import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Profile from '@phx-husky/profile';

import { Layout } from 'layout/Layout';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import { TProfilePageData } from './models';

import './ProfilePage.scss';

/**
 * Private template
 */
export const ProfilePage: FC<TProfilePageData> = ({
  data: {
    pageData,
    footer,
    header,
    headerAccount,
    headerNavigationLoggedIn,
    footerNavigationLoggedIn,
    redirects,
  },
  pageContext,
  location,
}) => {
  getBuildProcessInfo(pageContext);
  const { seo } = pageData;

  return (
    <Layout
      seo={seo}
      pageUid={pageContext.uid}
      footer={footer}
      footerNavigationLoggedIn={footerNavigationLoggedIn}
      header={header}
      headerNavigationLoggedIn={headerNavigationLoggedIn}
      headerAccount={headerAccount}
      redirects={redirects}
      location={location.pathname}
      privatePage
      className="profile-template"
      data-testid="ProfilePage"
    >
      <Profile />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishProfileTemplate(id: { eq: $id }) {
      uid
      seo {
        ...SeoFragment
      }
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    headerNavigationLoggedIn: contentstackFinishNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderNavigationLoggedInFragment
    }
    footerNavigationLoggedIn: contentstackFinishFooterNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...FooterNavigationLoggedInFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
  }
`;

export default withContentstackPreview(
  ProfilePage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_profile_template`
);
